<template>
  <b-form-group
    label="Marka"
    label-for="id_com_brand"
  >
    <v-select
      id="id_com_brand"
      v-model="filterParams.id_com_brand"
      placeholder="Marka"
      :options="brands"
      :reduce="item => item.id"
      label="name"
    />
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'
import { BFormGroup } from 'bootstrap-vue'

export default {
  name: 'SelectBrand',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    filterParams() {
      return this.$store.getters['serviceWorkOrderSmsReport/filterParams']
    },
  },
  created() {
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        where: {
          'com_brand.servicestatus': 1,
        },
      })
    },
  },
}
</script>
