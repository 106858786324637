<template>
  <div class="mt-1 pt-1 border-top">
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <select-brand />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <select-location />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <date-filters />
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          :disabled="loadingReport"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
        <b-button
          variant="warning"
          class="ml-1"
          @click="resetFilter"
        >
          <FeatherIcon icon="TrashIcon" />
          Filtreyi Sıfırla
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import SelectBrand from '@/views/Reports/Service/WorkOrdersSms/SelectBrand.vue'
import SelectLocation from '@/views/Reports/Service/WorkOrdersSms/SelectLocation.vue'
import DateFilters from '@/views/Reports/Service/WorkOrdersSms/DateFilters.vue'

export default {
  name: 'ServiceWorkOrdersSmsFilter',
  components: {
    BRow,
    BCol,
    BButton,
    DateFilters,
    SelectBrand,
    SelectLocation,
  },
  props: {
    filterAction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    loadingReport() {
      return this.$store.getters['serviceWorkOrderSmsReport/loadingReport']
    },
  },
  methods: {
    resetFilter() {
      this.$store.commit('serviceWorkOrderSmsReport/RESET_REPORT_FILTER_DATA')
      this.filterAction()
    },
  },
}
</script>
