<template>
  <b-form-group
    label="Lokasyon"
    label-for="id_com_location"
  >
    <v-select
      id="id_com_location"
      v-model="filterParams.id_com_location"
      placeholder="Lokasyon"
      :options="locations"
      :reduce="item => item.id"
      label="title"
    />
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'
import { BFormGroup } from 'bootstrap-vue'

export default {
  name: 'SelectLocation',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    filterParams() {
      return this.$store.getters['serviceWorkOrderSmsReport/filterParams']
    },
  },
  created() {
    this.getLocations()
  },
  methods: {
    getLocations() {
      this.$store.dispatch('locations/locationsList')
    },
  },
}
</script>
