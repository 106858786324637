<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col>
          <div class="d-flex align-items-center">
            <div>
              <b-avatar
                variant="light-danger"
                size="60"
              >
                <FeatherIcon
                  icon="ClockIcon"
                  size="24"
                />
              </b-avatar>
            </div>
            <div class="ml-1">
              <div class="font-weight-bold">
                Beklemede
              </div>
              <div class="font-weight-bolder font-medium-2">
                {{ reportData.counters.pending }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex align-items-center">
            <div>
              <b-avatar
                variant="light-success"
                size="60"
              >
                <FeatherIcon
                  icon="CheckCircleIcon"
                  size="24"
                />
              </b-avatar>
            </div>
            <div class="ml-1">
              <div class="font-weight-bold">
                Gönderildi
              </div>
              <div class="font-weight-bolder font-medium-2">
                {{ reportData.counters.sent }}
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <search-bar :filter-action="getReport" />
    </b-card-body>
    <b-table
      response="sm"
      :items="reportData.dataList"
      :fields="fields"
      striped
    >
      <template #cell(location)="data">
        <div>{{ data.item.location }}</div>
        <div class="text-danger font-weight-bold font-small-2">
          {{ data.item.brand }}
        </div>
      </template>
      <template #cell(work_no)="data">
        <div>{{ data.item.work_no }}</div>
        <div class="text-muted font-weight-bold font-small-2">
          {{ data.item.worker }}
        </div>
      </template>
      <template #cell(customer)="data">
        <div>{{ data.item.customer }}</div>
        <div class="text-muted font-weight-bold font-small-2">
          {{ data.item.company_name }}
        </div>
      </template>
      <template #cell(started)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            variant="light-success"
            size="30"
          >
            <FeatherIcon icon="CheckCircleIcon" />
          </b-avatar>
          <div class="ml-1">
            <div class="font-small-2">
              {{ moment(data.item.started).format('DD.MM.YYYY HH:mm') }}
            </div>
            <div class="text-success font-weight-bold font-small-2">
              Otomatik Gönderildi.
            </div>
          </div>
        </div>
      </template>
      <template #cell(ended)="data">
        <div
          v-if="data.item.ended"
          class="d-flex align-items-center"
        >
          <b-avatar
            variant="light-success"
            size="30"
          >
            <FeatherIcon icon="CheckCircleIcon" />
          </b-avatar>
          <div class="ml-1">
            <div class="font-small-2">
              {{ moment(data.item.ended).format('DD.MM.YYYY HH:mm') }}
            </div>
            <div class="text-info font-weight-bold font-small-2">
              {{ data.item.username }}
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-danger font-weight-bold"
        >
          <b-avatar
            variant="light-danger"
            size="30"
          >
            <FeatherIcon icon="ClockIcon" />
          </b-avatar>
          Beklemede
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-row>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="reportData.dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
        <b-col>
          <div class="text-right">
            <p>Toplam <b>{{ reportData.dataCount }}</b> adet sonuç bulundu.</p>
          </div>
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BRow, BCol, BAvatar, BCardFooter, BPagination,
} from 'bootstrap-vue'
import SearchBar from '@/views/Reports/Service/WorkOrdersSms/SearchBar.vue'

export default {
  name: 'WorkOrdersSms',
  components: {
    BPagination,
    BCardFooter,
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
    BAvatar,
    SearchBar,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      dataQuery: {
        limit: 50,
        start: 0,
      },
      fields: [
        {
          key: 'location',
          label: 'MARKA LOKASYON',
        },
        {
          key: 'work_no',
          label: 'İŞ EMRİ',
        },
        {
          key: 'customer',
          label: 'MÜŞTERİ',
        },
        {
          key: 'started',
          label: 'BAŞLANGIÇ SMS',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'ended',
          label: 'TESLİMAT SMS',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
      ],
    }
  },
  computed: {
    reportData() {
      return this.$store.getters['serviceWorkOrderSmsReport/reportData']
    },
    filterParams() {
      return this.$store.getters['serviceWorkOrderSmsReport/filterParams']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 25
      this.getDataList()
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      if (this.filterParams.id_com_brand) {
        this.dataQuery.id_com_brand = this.filterParams.id_com_brand
      } else {
        delete this.dataQuery.id_com_brand
      }
      if (this.filterParams.id_com_location) {
        this.dataQuery.id_com_location = this.filterParams.id_com_location
      } else {
        delete this.dataQuery.id_com_location
      }
      if (this.filterParams.sdate) {
        this.dataQuery.sdate = this.filterParams.sdate
      } else {
        delete this.dataQuery.sdate
      }
      if (this.filterParams.edate) {
        this.dataQuery.edate = this.filterParams.edate
      } else {
        delete this.dataQuery.edate
      }
      this.$store.dispatch('serviceWorkOrderSmsReport/getReport', this.dataQuery)
    },
  },
}
</script>
